import ky from 'ky';
import { getAccessToken } from '@cfacorp/ctrl-platform-ui-core-utils';
import logErrorToBugsnag from '../utils/logErrorToBugsnag';

const createApiClient = (baseUrl: string, type?: string) => {
  return ky.create({
    prefixUrl: baseUrl,
    retry: {
      limit: 2,
      statusCodes: [401, 403, 504, 500],
    },
    hooks: {
      beforeRequest: [
        request => {
          request.headers.set('Authorization', `Bearer ${getAccessToken()}`);
        },
      ],
      beforeError: [
        error => {
          logErrorToBugsnag(`DOP - API Error- - ${error.request.url}`, {
            response: error,
            context: 'Base API Client',
          });
          return error;
        },
      ],
    },
  });
};

export default createApiClient;
